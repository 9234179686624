<template>
	<main>
		<div class="section s2">
			<a-section class="section-wrap">
				<div class="title-box"></div>
				<div class="content-box">
					<div class="content">
						<p>随着AI大模型迭代加速及应用场景的不断丰富，数据作为推动AI发展的核心生产要素，其智能原料的地位被提到前所未有的高度。围绕高质量及大规模的数据供给、提升智能数据管理性能及效率，数据的存储、安全和可访问性变得至关重要，先进数据存力将会是构建AI差异化优势的关键，“以存强算”逐渐成为行业发展的新趋势。作为智能数据管理及中国企业数字化变革的赋能者，联想凌拓提供高效、安全且可扩展的解决方案，帮助企业释放数据潜力，加速业务创新。</p>
						<p>值此之际，我们诚挚地邀请您出席由联想凌拓主办的“AI赋智数据 – 联想凌拓技术大会（INSIGHT CHINA）。届时，我们将邀请业界专家、媒体、客户与生态合作伙伴欢聚一堂，分享在数据与智能交汇时代下数据基础设施的变革方向，探索企业如何利用生成式AI与智能数据基础架构的结合来打破数据孤岛，推动自主AI应用的全面落地，帮助企业实现从数据到智能的转型，携手共创智慧高效的数字未来。</p>
					</div>
					<div class="info">
						<div class="item">
							<div class="tit">会议时间:</div>
							<div class="text">2024年11月15日</div>
						</div>
						<div class="item">
							<div class="tit">会议地点:</div>
							<div class="text">上海 静安香格里拉大酒店 · 五层静安大宴会厅（上海市静安区延安中路1218号）</div>
						</div>
					</div>
				</div>
			</a-section>
		</div>
	</main>
</template>
<script>
export default {

}
</script>

<style lang="scss" scoped>
.s2{color:#111111;position: relative;
	.section-wrap{text-align: center;position: relative;z-index: 10;
		.title-box{display: inline-block; width: 537px;height:134px; background: url("~@/assets/image/2024/t1.png") no-repeat top center;background-size: contain;	}
		.content-box{margin-top: 30px;
			.content{padding:50px 50px 0;font-size: 18px;line-height:40px;text-align: left;
				p{text-indent:40px;}
			}
			.info{text-align: left;padding: 0 50px;font-size: 18px;line-height:40px;
				.item{display: flex;
					.tit{margin-right: 15px;}
					.text{flex: 1;}
				}
				.item:nth-last-child(1){margin-bottom: 0;}
			}
		}
	}
}
.s2::after{content: "";display: block;position:absolute;top:150px;right:0;width: 540px;height:180px;background: url("~@/assets/image/2024/i1.png") no-repeat right top;background-size:contain;}
@media screen and (max-width: 1230px) {
	.s2{
		.section-wrap{
			.title-box{width: middle(537px);height:middle(134px); padding: middle(60px) 0 middle(5px);}
			.content-box{margin-top: middle(30px);
				.content{padding: middle(50px) middle(50px) 0;font-size: middle(18px);line-height:middle(40px);
					p{text-indent:middle(40px);}
				}
				.info{padding: 0 middle(50px);font-size: middle(18px);line-height:middle(40px);
					.item{
						.tit{margin-right: middle(15px);}
					}
				}
			}
		}
	}
	.s2::after{top:middle(150px);width: middle(540px);height:middle(180px);}
}
@media screen and (max-width: 992px) {
	.s2{padding-top: 40px;
		.section-wrap{
			.title-box{width: 100%;height:67px;}
			.content-box{margin-top: 12px;
				.content{padding: 0;font-size: 14px;line-height:1.6;
					p{text-indent: 30px;}
				}
				.info{padding: 0;font-size: 14px;line-height:1.6;
					.item{
						.tit{margin-right: 7px;}
					}
				}
			}
		}
	}
	.s2::after{top:150px;width:270px;height:90px;}
}
</style>
