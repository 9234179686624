<template>
	<main>
		<div class="float-video" v-if="visible">
			<div class="mask"></div>
			<div class="content">
				<div class="video-box">
					<video class="video" :controls="play_status" x5-video-player-type="h5-page" webkit-playsinline="true" playsinline="true"  id="video" loop @click.prevent="pauseVideo" >
						<source :src="option.source">
					</video>
					<div class="mark" v-if="!play_status">
						<div class="play" @click="playVideo"></div>
					</div>
				</div>
				<div class="close" @click="close"></div>
			</div>
		</div>
	</main>
</template>

<script>
export default {
	data(){
		return {
			play_status:false,
			option:{},
			visible:false,
		}
	},
	methods:{
		init(option = {}){
			this.option = option;
			this.visible = true;
			this.$nextTick(()=>{
				this.playVideo();
			})
		},
		playVideo(){
			const video = document.getElementById('video');
			video.addEventListener('play',()=>{
				this.play_status = true;
			})
			video.addEventListener('pause',(event)=>{
				if (video.seeking) {
					return false;
				}
				this.play_status = false;
			})
			video.play();
		},
		pauseVideo(){
			const video = document.getElementById('video');
			video.pause();
		},
		close(){
			this.video = "";
			this.visible = false;
			this.$emit('close')
		}
	}
}
</script>
<style lang="scss" scoped>
.float-video{position: fixed;top:0;left:0;width: 100%;height:100%;z-index: $z-dialog;
	.mask{position: absolute;top:0;left:0;width: 100%;height:100%;background: rgba(0,0,0,.7);}
	.content{position: absolute;top:50%;left:50%;transform: translate(-50%,-50%);
		.video-box{position: relative;
			.video{display: block; width:750px;height:421px;background: #000;object-fit: contain;}
			.mark{position: absolute;top:0;left:0;width: 100%;height:100%;background: rgba(0,0,0,.2);display: flex;align-items: center;justify-content: center;
				.play{width: 58px;height:58px;background: url("~@/assets/image/15.png") no-repeat center center;background-size: contain;cursor: pointer;}
			}
		}
		.close{width: 48px;height:48px;margin: 30px auto 0;background: url("~@/assets/image/close.png") no-repeat center center;background-size: contain;cursor: pointer;}
	}
}
@media screen and (max-width: 1230px){
	.float-video{
		.content{
			.video-box{
				.video{width:middle(750px);height:middle(421px);}
				.mark{
					.play{width: middle(58px);height:middle(58px);}
				}
			}
			.close{width: middle(48px);height:middle(48px);margin: middle(30px) auto 0;}
		}
	}
}
@media screen and (max-width: 992px) {
	.float-video{
		.content{
			.video-box{
				.video{width: 100vw;height:calc(9/16*100vw);}
			}
			.close{width: 30px;height:30px;margin:30px auto 0;}
		}
	}
}
</style>
