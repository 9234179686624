<template>
	<main>
		<div class="section header">
			<div class="section-wrap">
				<div class="logo-box">
					<div class="logo">
						<img class="i1" :src="require('@/assets/image/2024/logo.png')" alt="logo">
					</div>
				</div>
				<div class="action-box">
					<div class="action" v-if="user_info.name">
						<div class="item">欢迎您，{{user_info.name}} !</div>
					</div>
					<div class="action" v-else>
						<div class="item" @click="toBook">注册</div>
						<div class="item" @click="toLogin">登录</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
export default {
	computed:{
		user_info(){
			return this.$store.state.views.user_info;
		}
	},
	methods:{
		toLogin(){
			this.$emit('toLogin')
		},
		toBook(){
			this.$emit('toBook')
		}
	}
}
</script>

<style lang="scss" scoped>
.header{position: absolute;top:0;left:0;width: 100%;z-index: $z-header;
	.section-wrap{display: flex;align-items: flex-start;justify-content: space-between;padding: 44px 57px;
		.logo-box{opacity:0;
			.logo{
				img{width: 187px;height:63px;display: block;object-fit: contain;}
			}
		}
		.action-box{
			.action{display: flex;align-items: center;padding: 15px 0;background: #c8e0f9;border-radius: 8px;
				.item{font-size: 22px;color:#3448a4;padding: 0 23px;position: relative;cursor: pointer;}
				.item::after{content: "";display: block;position: absolute;top:50%;right:0;width: 1px;height:18px;background:#8da4d7;transform: translateY(-50%);}
				.item:nth-last-child(1)::after{display: none;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.header{
		.section-wrap{padding: middle(44px) middle(57px);
			.action-box{
				.action{padding: middle(15px) 0;border-radius: middle(8px);
					.item{font-size:middle( 22px);padding: 0 middle(23px);}
					.item::after{height:middle(18px);}
				}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.header{
		.section-wrap{padding: 10px 13px;align-items: center;
			.logo-box{
				.logo{
					img{width: 50px;height:auto;}
				}
			}
			.action-box{
				.action{padding: 7px 0;
					.item{font-size: 13px;padding: 0 13px;}
					.item::after{height:10px;}
				}
			}
		}
	}
}
</style>
