<template>
	<main>
		<div class="section footer">
			<a-section class="section-wrap">
				<div class="copy-box">
					<div class="wrap">
						<div class="link-box">
							<a href="https://www.lenovonetapp.com/legal/" target="_blank" class="link">网站条款</a>
							<a href="https://www.lenovonetapp.com/legal/privacy.html" target="_blank" class="link">隐私政策</a>
							<a href="https://www.lenovonetapp.com/legal/cookie-policy.html" target="_blank" class="link">Cookie政策</a>
						</div>
						<div class="copy">版权所有：{{ year }} 联想凌拓 <a href="https://beian.miit.gov.cn/" target="_blank" class="link">京ICP备19022858号-1</a> </div>
					</div>
					<div class="beian"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802028921" target="_blank" class="link">京公网安备 11010802028921号</a></div>
				</div>
				<div class="share-box">
					<a href="https://www.lenovonetapp.com/QRcode.html" target="_blank" class="item"><div class="btn"></div></a>
					<a href="https://space.bilibili.com/502093372?spm_id_from=333.788.b_765f7570696e666f.2" target="_blank" class="item"><div class="btn"></div></a>
					<a href="https://app.mokahr.com/apply/lenovonetapp/24608#/" target="_blank" class="item"><div class="btn"></div></a>
					<a href="https://www.zhihu.com/org/lian-xiang-ling-tuo" target="_blank" class="item"><div class="btn"></div></a>
<!--					<a href="https://www.linkedin.com/authwall?trk=gf&trkInfo=AQGQmMkg1nZgSAAAAXeAcuOI4wl254wfYkG72CouzJCvtCtlXee_2jnUo3uY8qvnbB3M1JaF0l7HTIOU-KrxrytTlBgp9X1uKN7npOCNrkSVm_WOGAbIygmSDpgwhzJYBilr2t0=&originalReferer=&sessionRedirect=https%3A%2F%2Fcn.linkedin.com%2Fcompany%2Flenovonetapp"  target="_blank" class="item"><div class="btn"></div></a>-->
				</div>
			</a-section>
		</div>
	</main>
</template>

<script>
import moment from "moment";

export default {
	computed:{
		year(){
			return moment().year();
		}
	}
}
</script>
<style lang="scss" scoped>
.footer{background: #193591;padding: 50px 0;position: relative;z-index: 3;margin-top: 120px;
	.section-wrap{display: flex;justify-content: space-between;
		.copy-box{font-size: 16px;color:#fff;
			.link{color:#fff;}
			.wrap{display: flex;flex-wrap: wrap;
				.link-box{margin-right: 15px;display: flex;
					.link{display: block; position: relative;padding: 0 6px;}
					.link::after{content: "";display: block;position: absolute;top:50%;right:0;width: 1px;height:16px;background:#fff;transform: translateY(-50%);}
					.link:nth-child(1){padding-left: 0;}
					.link:nth-last-child(1){padding-right: 0;}
					.link:nth-last-child(1)::after{display: none;}
				}
			}
			.beian{margin-top: 10px;padding-left: 22px;background: url("~@/assets/image/14.png") no-repeat left center;background-size: 18px;}
		}
		.share-box{display: flex;align-items: center;
			.item{width: 36px;height:36px;margin-right: 40px;}
			@for $i from 1 through 5{
				.item:nth-child(#{$i}){background: url("~@/assets/image/#{$i+8}.png") no-repeat center center;background-size: contain;}
			}
		}
	}
}
@media screen and (max-width: 1230px) {
	.footer{padding:middle( 50px) 0;margin-top: m(120px);
		.section-wrap{
			.copy-box{font-size: middle(16px);
				.wrap{
					.link-box{margin-right:middle(15px);
						.link{padding: 0 middle(6px);}
						.link::after{height:middle(16px);}
					}
				}
				.beian{margin-top: middle(10px);padding-left: middle(22px);background-size:middle( 18px);}
			}
			.share-box{
				.item{width: middle(36px);height:middle(36px);margin-right:middle( 40px);}
			}
		}
	}
}
@media screen and (max-width: 992px) {
	.footer{padding: 20px 0;margin-top:60px;
		.section-wrap{flex-direction: column;
			.copy-box{font-size: 11px;
				.wrap{flex-direction: column;
					.link-box{}
					.copy{margin-top: 5px;}

				}
				.beian{margin-top: 5px;}
			}
			.share-box{margin-top: 25px;}
		}
	}
}
</style>
